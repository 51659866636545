import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import marked from "marked"
import Confluent_logo from "../images/confluent_logo.png"
import PageLayout from "../components/hbnfpagelayout"
import Head from "../components/head"
export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      sponsorDetails {
        heading
        details {
          details
        }
        sponsorHeading
        host {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        goldSponsors {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        silverSponsors {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        startupSponsors {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        communityPartners {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        otherSponsors {
          name
          details {
            details
          }
          websiteUrl
          partnerType
          logo {
            file {
              url
            }
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const Sponsors = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [selectedSponsor, setSelectedSponsor] = useState()
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  const popupopen = sponsor => () => {
    setSelectedSponsor(sponsor)
    document.getElementById("event_popup").style.display = "flex"
  }
  const popupclose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={
          props?.data?.contentfulEvents?.sponsorDetails?.seoMetadata?.title
        }
        description={
          props?.data?.contentfulEvents?.sponsorDetails?.seoMetadata
            ?.description?.description
        }
      />
      <div className="sponsors_body">
        <div className="sponsors_talk_main">
          <h2
            className="sponsors_talk_title"
            style={{
              background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {props?.data?.contentfulEvents?.sponsorDetails?.heading}
          </h2>
          <div className="sponsors_talk_details">
            <p style={{ whiteSpace: "pre-wrap" }}>
              {props?.data?.contentfulEvents?.sponsorDetails?.details?.details}
            </p>
            <Link
              to={`/events/${props?.data?.contentfulEvents?.slug}/become-a-sponsor`}
            >
              Become a Sponsor
            </Link>
          </div>
        </div>
        <div className="sponsors_heading">
          {props?.data?.contentfulEvents?.sponsorDetails?.sponsorHeading}
        </div>
        {props?.data?.contentfulEvents?.sponsorDetails?.host !== null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Host</div>
            <div className="sponsors_logo">
              {props?.data?.contentfulEvents?.sponsorDetails?.host?.map(
                (item, index) => {
                  return (
                    <img
                      src={Confluent_logo}
                      alt={item?.name}
                      key={index}
                      onClick={item?.details !== null ? popupopen(item) : ""}
                      style={{
                        pointerEvents: item?.details === null ? "none" : "",
                      }}
                    />
                  )
                }
              )}
            </div>
          </div>
        )}
        {props?.data?.contentfulEvents?.sponsorDetails?.goldSponsors !==
          null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Gold</div>
            <div className="sponsors_logos">
              {props?.data?.contentfulEvents?.sponsorDetails?.goldSponsors?.map(
                (item, index) => {
                  return (
                    <img
                      src={item.logo.file.url}
                      alt={item.name}
                      key={index}
                      onClick={item?.details !== null ? popupopen(item) : ""}
                      style={{
                        pointerEvents: item?.details === null ? "none" : "",
                      }}
                    />
                  )
                }
              )}
            </div>
          </div>
        )}
        {props?.data?.contentfulEvents?.sponsorDetails?.silverSponsors !==
          null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Silver</div>
            <div className="sponsors_logos">
              {props?.data?.contentfulEvents?.sponsorDetails?.silverSponsors?.map(
                (item, index) => {
                  return (
                    <img
                      src={item.logo.file.url}
                      alt={item.name}
                      key={index}
                      onClick={item?.details !== null ? popupopen(item) : ""}
                      style={{
                        pointerEvents: item?.details === null ? "none" : "",
                      }}
                    />
                  )
                }
              )}
            </div>
          </div>
        )}
        {props?.data?.contentfulEvents?.sponsorDetails?.startupSponsors !==
          null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Startup</div>
            <div className="sponsors_logos">
              {props?.data?.contentfulEvents?.sponsorDetails?.startupSponsors?.map(
                (item, index) => {
                  return (
                    <img
                      src={item.logo.file.url}
                      alt={item.name}
                      key={index}
                      onClick={item?.details !== null ? popupopen(item) : ""}
                      style={{
                        pointerEvents: item?.details === null ? "none" : "",
                      }}
                    />
                  )
                }
              )}
            </div>
          </div>
        )}
        {props?.data?.contentfulEvents?.sponsorDetails?.communityPartners !==
          null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Community Partners</div>
            <div className="sponsors_logos">
              {props?.data?.contentfulEvents?.sponsorDetails?.communityPartners?.map(
                (item, index) => {
                  return (
                    <img
                      src={item.logo.file.url}
                      alt={item.name}
                      key={index}
                      onClick={item?.details !== null ? popupopen(item) : ""}
                      style={{
                        pointerEvents: item?.details === null ? "none" : "",
                      }}
                    />
                  )
                }
              )}
            </div>
          </div>
        )}
        {props?.data?.contentfulEvents?.sponsorDetails?.otherSponsors !==
          null && (
          <div className="sponsors_row">
            <div className="sponsors_title">Other Partners</div>
            <div className="sponsors_logos">
              {props?.data?.contentfulEvents?.sponsorDetails?.otherSponsors?.map(
                (item, index) => {
                  return (
                    <figure>
                      <img
                        src={item.logo.file.url}
                        alt={item.name}
                        key={index}
                        onClick={item?.details !== null ? popupopen(item) : ""}
                        style={{
                          pointerEvents: item?.details === null ? "none" : "",
                        }}
                      />
                      {item?.partnerType && (
                        <figcaption>{item?.partnerType}</figcaption>
                      )}
                    </figure>
                  )
                }
              )}
            </div>
          </div>
        )}
      </div>
      <div className="event_popup" id="event_popup">
        <div className="event_popup_body">
          <div className="event_popup_close">
            <span onClick={popupclose}>X</span>
          </div>
          <div className="event_popup_log">
            <a
              href={selectedSponsor?.websiteUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={selectedSponsor?.logo?.file?.url} alt="logo" />
            </a>
          </div>
          <div className="event_popup_title">{selectedSponsor?.name}</div>
          <div
            className="event_popup_info"
            dangerouslySetInnerHTML={{
              __html: marked.parse(selectedSponsor?.details?.details ?? ""),
            }}
          ></div>
        </div>
      </div>
    </PageLayout>
  )
}
export default Sponsors
